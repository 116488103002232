* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif; }

body {
  min-height: 100vh;
  margin: 0 15px;
  position: relative;
  @media (min-width: 768px) {
    margin: 0 20px; }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ),url('./assets/lakeside.jpg');
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; } }



.weather-app {
  height: 100%;
  margin: 0 auto;
  position: relative; }




